<template>
    <v-app-bar
        id="app-bar"
        app
        shrink-on-scroll
        flat
        prominent
        short
    >
        <v-toolbar-title class="left-buffer">
            <a href="/" class="top-padding">
                <img 
                    :width="$vuetify.breakpoint.smAndUp ? (scrollDepth <= 50 ? 300: 'auto'): 'auto'" 
                    :height="$vuetify.breakpoint.smAndUp ? (scrollDepth <= 50 ? 'auto': 30): 30" 
                    src="../assets/riverview_text.png"
                />
            </a>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        
        <img 
            :width="$vuetify.breakpoint.lgAndUp ? 300: 250" 
            height="auto"
            v-show="$vuetify.breakpoint.mdAndUp ? ($vuetify.breakpoint.lgAndUp ? (scrollDepth <= 22 ? true: false): (scrollDepth <= 47 ? true: false)): false" 
            class="top-buffer" 
            src="../assets/barn.png"
        />
        <v-spacer style="width: 20%"></v-spacer>
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-app-bar-nav-icon v-bind="attrs" v-on="on" v-show="$vuetify.breakpoint.smAndUp ? false: true" ></v-app-bar-nav-icon>
            </template>
            <v-list>
                <v-list-item
                    class="left-buffer" 
                    to="/#About"
                    @click="$vuetify.goTo('#About')"
                >
                    <v-list-item-title>About</v-list-item-title>
                </v-list-item>
                <!-- <v-list-item
                    to="/#Gallery"
                    @click="$vuetify.goTo('#Gallery')"
                >
                    <v-list-item-title>Gallery</v-list-item-title>
                </v-list-item> -->
                <v-list-item
                    to="/#Amenities"
                    @click="$vuetify.goTo('#Amenities')"
                >
                    <v-list-item-title>Amenities</v-list-item-title>
                </v-list-item>
                <v-list-item
                    to="/Gallery"
                    @click="$vuetify.goTo('/Gallery')"
                >
                    <v-list-item-title>Gallery</v-list-item-title>
                </v-list-item>
                <v-list-item 
                    to="/Calendar"
                >
                    <v-list-item-title>Calendar</v-list-item-title>
                </v-list-item>
                <v-list-item 
                    class="right-buffer" 
                    to="/Contact"
                >
                    <v-list-item-title>Contact</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <!-- <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
        </v-btn> -->

        <template v-slot:extension v-if="$vuetify.breakpoint.smAndUp ? true: false">
            <v-tabs
                color="#000000"
                slider-color="#6F7C80"
            >
                <!-- <v-spacer style="max-width: 60px;"/> -->
                <v-tab 
                    class="left-buffer" 
                    to="/#About"
                    @click="$vuetify.goTo('#About')"
                >
                About</v-tab>
                <!-- <v-tab 
                    to="/#Gallery"
                    @click="$vuetify.goTo('#Gallery')"
                >
                Gallery</v-tab> -->
                <v-tab 
                    to="/#Amenities"
                    @click="$vuetify.goTo('#Amenities')"
                >
                Amenities</v-tab>
                <v-spacer/>
                <v-tab
                    to="/Gallery"
                >
                Gallery</v-tab>
                <v-tab 
                    to="/Calendar"
                >
                Calendar</v-tab>
                <v-tab 
                    class="right-buffer" 
                    to="/Contact"
                >
                Contact</v-tab>
            </v-tabs>
        </template>

    </v-app-bar>
</template>
<script>
export default {
    data: () => ({
        scrollDepth: 0
    }),
    methods: {
        scroll() {
            var scroll = window.scrollY;
            let x = document.getElementById("app-bar").scrollTop=scroll;
            this.scrollDepth = x
        }
    },
    mounted() {
        document.addEventListener("scroll", this.scroll)
    }
}
</script>
<style scoped>
html {
  scroll-behavior: smooth;
}
.left-buffer {
    margin-left: 3%;
}
.right-buffer {
    margin-right: 3%;
}
.top-buffer {
    margin-top: 20px;
}
.top-padding {
    padding-top: 15px;
}
.links {
    text-decoration: none;
    color: #000000;
}
</style>